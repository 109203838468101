<template>
  <div>
    <small class="d-block mb-3 d-flex align-items-center">
      <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
      O documento probatório dos admitidos e demitidos de cada mês de análise deverá ser obtido pelos dados do e-social ou
      através de relatório gerado pelo ERP de Folha utilizado pela empresa. Caso seja um único relatório com todos os
      períodos requeridos, o mesmo deverá ser anexado todos os meses, repetidamente. A ausência do anexo, afetará a
      pontuação da empresa.
    </small>
    <div class="mb-4 px-0" v-if="items && items.length">
      <p>Total de colaboradores em {{ items[0].title }}</p>
      <b-input class="col-md-2" type="text" @keypress="isNumberWithoutDot($event)" v-model="items[0].total_initial"
        @change="handleInputChange" :class="'is-invalid' && !totalFirst" />
    </div>
    <b-list-group>
      <b-list-group-item v-for="(month, index) in newItems" :key="index" class="px-0">
        <span>
          <b-icon icon="calendar" class="mr-2" />
          <strong>{{ month.title }}</strong>
        </span>
        <div class="d-flex align-items-center justify-content-between">
          <div class="mt-2 d-flex align-items-center">
            <b-form-group label="Adimitidos" class="mr-2" :description="`Adimitidos em ${month.title}`">
              <b-input @keypress="isNumberWithoutDot($event)" type="text" :tabindex="index + 1"
                @change="handleInputChange" :disabled="!month.total_initial" v-model="month.admitted" />
            </b-form-group>
            <b-form-group label="Demitidos" :description="month.fired <= month.total_initial
              ? `Demitidos em ${month.title}`
              : ''
              ">
              <b-input @keypress="isNumberWithoutDot($event)" type="text" :tabindex="index + 2"
                @change="handleInputChange" :disabled="!month.total_initial" v-model="month.fired"
                :class="month.fired > month.total_initial && 'is-invalid'" />
              <b-form-invalid-feedback v-if="month.fired > month.total_initial">
                Este valor não pode ser maior que
                {{ month.total_initial }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="mt-3">
            <span class="mr-4">
              Inicial: <strong>{{ month.total_initial || 0 | number }}</strong>
            </span>
            <span class="mr-4">
              Final: <strong>{{ month.total_final || 0 | number }}</strong>
            </span>
            <span class="d-flex" v-if="month.turn_over">
              Rotatividade:
              <strong class="ml-1">
                {{ month.turn_over.toFixed(2) || 0 }} %
              </strong>
            </span>
          </div>
          <div class="mt-3">
            <section v-if="month.e_social" class="has-inputed">
              <b-button variant="success" size="sm" class="mb-0 ml-2 btn-attachment" @click="handleOpenEvidences(month)">
                <span class="d-flex">
                  <b-icon icon="wallet-fill" class="mr-2" />
                  <span class="text-nowrap" v-if="month.e_social">
                    E-social
                  </span>
                </span>
              </b-button>
            </section>
            <b-button v-else @click="handleAddEvidence(month, index)" :variant="month.in_compliance ? 'primary' : 'link'"
              class="mb-0 attachment ml-4" size="sm">
              <b-icon icon="exclamation-triangle-fill" v-b-popover.hover.top="'É necessário adicionar o e-social'"
                variant="warning" class="mr-2" />
              <b-icon icon="paperclip" class="mr-1" />
              <span>Adicionar e-social</span>
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-pagination v-if="items && items.length" @change="handleChangePage" v-model="current_page" :total-rows="total"
      :per-page="per_page" aria-controls="my-table" />
    <b-modal id="add-e-social" size="md" hide-footer centered title="Adicionar e-social">
      <file-upload @upload="handleUploadEvidence" />
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleDeleteProfile()">
          Salvar
        </b-button>
      </template>
    </b-modal>
    <b-modal id="e-social" size="md" hide-footer centered :title="`Editar e-social do ${uploadedFile.name}`">
      <b-card no-body class="p-2" v-if="uploadedFile && uploadedFile.name">
        <span class="d-block mb-2"> E-social anexado </span>
        <a class="text-left d-flex align-items-center line-height-1" v-bind:href="uploadedFile.file" target="_blank"
          download>
          <b-icon icon="paperclip" class="mr-2 m-0" />
          <span> {{ uploadedFile.name }}</span>
        </a>
      </b-card>

      <file-upload @upload="handleUploadEvidence" :actiontLabel="`Substituir e-social '${uploadedFile.name}'`" />
    </b-modal>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload";
import { listPaginated } from "@/mixins/listPaginated";
import { isNumberWithoutDot } from "@/utils/validations/isNumber.js";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  components: {
    FileUpload,
  },
  data() {
    return {
      totalFirst: null,
      action_name: "caged",
      clear_list: false,
      months: [],
      isNumberWithoutDot,
      uploadedFile: [],
      selectedMonth: {},
      newItems: [],
    };
  },

  methods: {
    ...mapActions(["edit_caged", "show_toast"]),

    handleInputChange() {
      const items = this.handleChangeTotal();

      const validform = items.every((item) => item.fired <= item.total_initial);

      if (!validform) {
        return;
      }

      this.edit_caged(items).then(() => {
        this.getList();
      });
    },

    getTotalInitial(month, index) {
      if (index === 0) {
        return +month.total_initial;
      }
      return +this.items[index - 1]?.total_final;
    },

    getTotalFinal(month) {
      return +month.total_initial + +month.admitted - +month.fired;
    },

    getTurnOver(month) {
      let turnOver = 0;
      if (+month.total_final > 0) {
        turnOver = +month.admitted + (+month.fired / +month.total_final) * 50;
      }
      return turnOver > 0 ? +turnOver.toFixed(2) : turnOver;
    },

    handleChangeTotal() {
      return this.items?.map((month, index) => {
        month.e_social = month.e_social?.id
          ? month.e_social.id
          : month.e_social;

        const total_initial = this.getTotalInitial(month, index);

        const total_final = this.getTotalFinal(month);

        const turn_over = this.getTurnOver(month);

        return {
          ...month,
          total_initial,
          total_final,
          turn_over: turn_over,
        };
      });
    },

    handleOpenEvidences(month) {
      this.selectedMonth = month;
      this.$bvModal.show("e-social");
      this.uploadedFile = month.e_social;
    },

    async handleUploadEvidence(e_social) {
      const index = this.items.indexOf(this.selectedMonth);

      this.items[index].e_social = e_social[0].id;

      const items = await this.handleChangeTotal().map((month) => ({
        ...month,
        e_social: month.e_social?.id ? month.e_social.id : month.e_social,
      }));

      await this.edit_caged(items).then(() => {
        this.$bvModal.hide("add-e-social");
        this.$bvModal.hide("e-social");
        this.$forceUpdate();
        this.getList();
      });
    },
    handleAddEvidence(month) {
      this.selectedMonth = month;
      this.$bvModal.show("add-e-social");
    },
  },

  watch: {
    items: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.newItems = newVal;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-attachment {
  min-width: 157px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
